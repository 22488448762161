import styled from '@emotion/styled';
import rhythm from '../../../utils/rhythm';
import colors from '../../../styles/modules/colors.module.scss';
import utilities from '../../../styles/modules/utilities.module.scss';

const Styles = styled.nav((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  textAlign: 'center',
  background: colors.light,
  height: '100vh',
  minWidth: '75vw',
  padding: rhythm(1),
  position: 'fixed',
  top: 0,
  right: 0,
  zIndex: 1000,
  transition: utilities.transition,
  transform: props.open ? 'translateX(0)' : 'translateX(100%)',

  ul: {
    display: 'flex',
    flexDirection: 'column',
  },

  a: {
    color: colors.primary,
    fontSize: rhythm(1),
    padding: rhythm(1 / 2),
    display: 'block',
  },
}));

export default Styles;
