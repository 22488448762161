import React from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import {Col, Container, Row} from "react-bootstrap";

import Styles from './footer.styles';
import { ReactComponent as LogoWhite } from '../../images/logos/logo.svg';
import { ReactComponent as IconEmail } from '../../images/icons/icon-email.svg';
import { ReactComponent as IconInstagram } from '../../images/icons/icon-instagram.svg';
import { ReactComponent as IconFacebook } from '../../images/icons/icon-facebook.svg';

export default function Footer() {
  const data = useStaticQuery(graphql`
      query FooterQuery {
          wp {
              options {
                  optionsSocialMedia {
                      emailAddress
                      facebook
                      instagram
                  }
              }
          }
      }
  `);
  return (
    <Styles>
      <Container>
        <Row>
          <Col md={4} xs={6} className="pb-3">
            <LogoWhite/>
          </Col>

          <Col md={4} xs={12}>
            <Row>
              <Col md={6} xs={6}>
                <h5>Learn More</h5>
                <ul className='list-unstyled'>
                  <li>
                    <Link to={'/learn-more/'}>About Us</Link>
                  </li>
                  <li>
                    <Link to={'/learn-more/#the-team'}>The Team</Link>
                  </li>
                  <li>
                    <Link to={'/learn-more/#contributors'}>Contributors</Link>
                  </li>
                </ul>
              </Col>

              <Col md={6} xs={6}>
                <h5>Get Started</h5>
                <ul className='list-unstyled'>
                  <li>
                    <Link to={'/get-started/'}>Start Justli</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>

          <Col md={4} xs={12}>
            <Row>
              <Col md={6} xs={6}>
                <h5>Browse</h5>
                <ul className='list-unstyled'>
                  <li>
                    <Link to={'/browse/'}>Browse Resources</Link>
                  </li>
                </ul>
              </Col>

              <Col md={6} xs={6}>
                <h5>Connect</h5>
                <ul className='list-unstyled'>
                  <li>
                    <Link to={'/connect/#subscribe'}>Subscribe</Link>
                  </li>
                  <li>
                    <Link to={'/connect/#actions'}>Contribute</Link>
                  </li>
                  <li>
                    <Link to={'/connect/#actions'}>Submit</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <hr className='ml-5' />

      <Container className="d-flex flex-row pt-2">
        <div className='d-inline-block'>© Justli
          <span className={`d-none d-md-inline-block`}>&nbsp;|&nbsp;</span>
          <span className={`d-block d-md-none`}/>
          Built By <a href={`https://generativ.io`} target={`_blank`} className={`d-inline-block`}><strong>Generativ</strong></a></div>

        <ul className="nav navbar-nav ml-auto flex-row align-content-center">
          <li>
            <a href={`mailto:${data.wp.options.optionsSocialMedia.emailAddress}`}>
              <IconEmail/>
            </a>
          </li>
          {/* eslint-disable react/jsx-no-target-blank */}
          <li>
            <a href={data.wp.options.optionsSocialMedia.instagram} target='_blank'>
              <IconInstagram/>
            </a>
          </li>
          <li>
            <a href={data.wp.options.optionsSocialMedia.facebook} target='_blank'>
              <IconFacebook/>
            </a>
          </li>
          {/* eslint-enable react/jsx-no-target-blank */}
        </ul>
      </Container>
    </Styles>
  );
}
