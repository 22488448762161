import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Styles from './mobileMenu.styles';

// Based on: https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/
const MobileMenu = ({ open }) => {
  return (
    <Styles open={open}>
      <ul className="list-unstyled text-center">
        <li>
          <Link to="/learn-more/">
            Learn More
          </Link>
        </li>
        <li>
          <Link to="/get-started/">
            Get Started
          </Link>
        </li>
        <li>
          <Link to="/browse/">
            Browse
          </Link>
        </li>
        <li>
          <Link to="/connect/">
            Connect
          </Link>
        </li>
      </ul>
    </Styles>
  );
};

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default MobileMenu;
