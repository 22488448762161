import styled from '@emotion/styled';
import rhythm from '../../utils/rhythm';
import colors from '../../styles/modules/colors.module.scss';

const Styles = styled.div({
  padding: `${rhythm(1 / 8)} 0`,
  backgroundColor: colors.primary,

  a: {
    color: colors.light,
  },
});

export default Styles;
