import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import GlobalStyles from '../../utils/global.styles';
import Header from '../header/header';
import Footer from '../footer/footer';
import BurgerButton from '../mobileNav/burgerButton/burgerButton';
import MobileMenu from '../mobileNav/mobileMenu/mobileMenu';
import { useOnClickOutside } from '../mobileNav/hooks/useOnClickOutside';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setMobileNavOpen(false));

  return (
    <>
      <GlobalStyles />
      <div ref={node} className={`d-block d-md-none`}>
        <BurgerButton setOpen={setMobileNavOpen} open={mobileNavOpen} />
        <MobileMenu open={mobileNavOpen} />
      </div>
      <Header siteTitle={data.site.siteMetadata.title} />
      <>{children}</>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
