import styled from '@emotion/styled';
import colors from '../../styles/modules/colors.module.scss';
import rhythm from '../../utils/rhythm';

const Styles = styled.footer({
  padding: `${rhythm(3)} 0`,
  backgroundColor: colors.primary,
  color: colors.white,

  svg: {
    marginTop: `-${rhythm(2 / 3)}`,
  },

  h5: {
    textTransform: 'uppercase'
  },

  a: {
    color: colors.white,
    fontSize: rhythm(2 / 3),
    display: 'block',
  },

  hr: {
    borderColor: colors.white,
  },
});

export default Styles;
