import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';

import Styles from './header.styles';
import { ReactComponent as LogoWhite } from '../../images/logos/logo.svg';

const Header = () => (
  <Styles>
    <Container className="d-flex flex-row p-2">
      <Link to="/" className="navbar-brand">
        <LogoWhite/>
      </Link>

      <ul className="nav navbar-nav ml-auto flex-row align-content-center d-none d-md-flex">
        <li>
          <Link to="/learn-more/">
            Learn More
          </Link>
        </li>
        <li>
          <Link to="/get-started/">
            Get Started
          </Link>
        </li>
        <li>
          <Link to="/browse/">
            Browse
          </Link>
        </li>
        <li>
          <Link to="/connect/">
            Connect
          </Link>
        </li>
      </ul>
    </Container>
  </Styles>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
